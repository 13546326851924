import { render, staticRenderFns } from "./ProfileCreateForm.vue?vue&type=template&id=907b192a&scoped=true"
import script from "./ProfileCreateForm.vue?vue&type=script&lang=js"
export * from "./ProfileCreateForm.vue?vue&type=script&lang=js"
import style0 from "./ProfileCreateForm.vue?vue&type=style&index=0&id=907b192a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "907b192a",
  null
  
)

export default component.exports