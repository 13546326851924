//import { createPhotoInput } from "@/utils/converters"
import file from "@/api/file.js"

export async function uploadProfilePhotos(
  profilePhotoUpload,
  showcasePhotosUpload
  //vue
) {
  try {
    let profilePhoto
    if (profilePhotoUpload) {
      profilePhoto = await file.uploadFile(profilePhotoUpload)
      profilePhoto = profilePhoto?.data
      console.log("profilePhoto", profilePhoto)
    }

    //: {}
    const showcasePhotoResponses =
      showcasePhotosUpload?.length > 0
        ? await Promise.all(showcasePhotosUpload.map((f) => file.uploadFile(f)))
        : []
    /*
    const showcasePhotoUploadResponses = await Promise.all(
      showcasePhotoInputs.map((file) =>
        file.uploadFile(file)
        /*
        vue.$store.dispatch("api/uploadFile", {
          name: name.split(".").slice(0, -1)[0],
          data,
          type: "showcasePhoto",
        })
      )
    )
    */
    const showcasePhotos = showcasePhotoResponses
      .map(({ data }) => data?._id)
      .filter(Boolean)
    return { profilePhoto: profilePhoto?._id, showcasePhotos }
  } catch (error) {
    console.log("error", error)
    throw new Error(
      error.msg || "Something went wrong while uploading profile photos."
    )
  }
}
