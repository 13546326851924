<template>
  <form class="form-container">
    <!-- Head -->
    <div class="form-content-header">
      <v-stepper :value="step + 1" color="#4879C9" alt-labels>
        <v-stepper-header>
          <template v-for="(n, index) in stepNames">
            <v-stepper-step
              :step="index + 1"
              :key="index"
              :complete="stepComplete(index)"
              :color="index > step ? '#D6E1F3' : '#4879C9'"
            >
              {{ stepNames[index] }}
            </v-stepper-step>
            <v-divider v-if="index < totalSteps" :key="index + 100"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </div>
    <!-- Form -->
    <div class="form-content-body profile-form">
      <div class="responsive-width mx-auto">
        <slot></slot>
      </div>
    </div>
    <!-- Footer -->
    <div class="form-content-footer d-flex">
      <DefaultButton
        v-show="step !== 0"
        :labelText="'← Back'"
        :btnType="['back-btn']"
        @click="handleStepChange(step - 1)"
      />
      <DefaultButton
        :labelText="step === totalSteps ? 'Submit' : 'Next →'"
        :btnClass="['right-end']"
        @click="$emit('validate')"
        :disabled="loading ? true : false"
      />
    </div>
  </form>
</template>

<script>
import DefaultButton from "../../components/DefaultButton.vue"
export default {
  components: { DefaultButton },
  props: {
    step: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    profileType: {
      type: String,
    },
  },
  data() {
    const providerSteps = [
      "Contact info",
      "Business info",
      "Services",
      "Credentials",
      "Additional info",
    ]
    const jobseekerSteps = [
      "Basic Information",
      "Experience & Certifications",
      "Work History",
    ]
    return {
      stepNames:
        this.profileType == "jobseeker" ? jobseekerSteps : providerSteps,
    }
  },
  methods: {
    handleStepChange(step) {
      this.$emit("handle-step-change", step)
    },
    stepComplete(st) {
      return st < this.$props.step
    },
  },
}
</script>

<style lang="scss" scoped>
.responsive-width {
  width: 100%;
}
.disclaimer-text {
  font-style: italic;
  width: 80%;
  text-align: center;
  color: red;
  padding: 0 50px;
}
</style>
