var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.positionSeeking } ],attrs:{"label":"What type of position are you seeking?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('span',[_vm._v("Select all that apply")]),_c('b-form-checkbox-group',{staticClass:"gfb-radio-container",attrs:{"id":"checkbox-group-1","options":_vm.positionOptions,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.positionSeeking),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "positionSeeking", $$v)},expression:"fields.jobSeeker.positionSeeking"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.positionSeeking),expression:"errors.positionSeeking"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.positionSeeking))])]}}])}),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.hasManagementExperience } ],attrs:{"label":"Do you have project or jobsite management experience?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.hasManagementExperience),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "hasManagementExperience", $$v)},expression:"fields.jobSeeker.hasManagementExperience"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.hasManagementExperience),expression:"errors.hasManagementExperience"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.hasManagementExperience))])]}}])}),(_vm.fields.jobSeeker.hasManagementExperience)?_c('div',{staticClass:"mb-5"},[_c('b-form-group',{attrs:{"label":"Select all that apply to your management experience"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.managementExperienceOptions,"selectedList":_vm.fields.jobSeeker.managementExperienceCategories,"selectLabel":"Search or select experience"},on:{"remove-option":_vm.removeManagementExperience,"select-option":_vm.selectManagementExperience}})],1)],1):_vm._e(),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.hasHandsOnExperience } ],attrs:{"label":"Do you have any hands-on experience working in the skilled trades/construction industry?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.hasHandsOnExperience),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "hasHandsOnExperience", $$v)},expression:"fields.jobSeeker.hasHandsOnExperience"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.hasHandsOnExperience),expression:"errors.hasHandsOnExperience"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.hasHandsOnExperience))])]}}])}),(_vm.fields.jobSeeker.hasHandsOnExperience === false)?_c('div',{staticClass:"mb-5"},[_c('b-form-group',{attrs:{"label":"Please select any trade categories that you are interested in finding\n        paid training opportunities"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.categoryOptions,"selectedList":_vm.fields.jobSeeker.handsOnExperiencesTrainings,"selectLabel":"Search or select category"},on:{"remove-option":_vm.removeTraining,"select-option":_vm.selectTraining}})],1)],1):_vm._e(),(_vm.fields.jobSeeker.hasHandsOnExperience)?_c('div',[_c('b-form-group',{attrs:{"label":"Select all that apply to your hands on experience"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.categoryOptions,"selectedList":_vm.handsOnExperienceCategories,"selectLabel":"Search or select category"},on:{"remove-option":_vm.removeExperience,"select-option":_vm.selectExperience}})],1),_c('div',{staticClass:"accordion my-5",attrs:{"role":"tablist"}},_vm._l((_vm.fields.jobSeeker.handsOnExperiences),function(experience,index){return _c('b-card',{key:experience.category,staticClass:"mb-4 experience-card",attrs:{"no-body":""}},[_c('div',{staticClass:"bg-primary py-2 text-center text-white category-heading"},[_vm._v(" "+_vm._s(experience.category)+" ")]),_c('b-card-body',[_c('b-form-group',{class:[
              'gfb-radio-container',
              'required',
              {
                'pill-error':
                  _vm.errors.handsOnExperiences &&
                  _vm.errors.handsOnExperiences[index] &&
                  _vm.errors.handsOnExperiences[index].amount,
              } ],attrs:{"label":"How much experience do you have in this trade category?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.howMuchExperienceOptions,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.handsOnExperiences[index].amount),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker.handsOnExperiences[index], "amount", $$v)},expression:"fields.jobSeeker.handsOnExperiences[index].amount"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.handsOnExperiences &&
                _vm.errors.handsOnExperiences[index] &&
                _vm.errors.handsOnExperiences[index].amount
              ),expression:"\n                errors.handsOnExperiences &&\n                errors.handsOnExperiences[index] &&\n                errors.handsOnExperiences[index].amount\n              "}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.handsOnExperiences && _vm.errors.handsOnExperiences[index] && _vm.errors.handsOnExperiences[index].amount))])]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Please rate your experience in this category"}},[_c('ExperienceSliderContainer',{attrs:{"rating":_vm.fields.jobSeeker.handsOnExperiences[index].rating},on:{"on-change":function (rating) { return _vm.updateRating(index, rating); }}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.handsOnExperiences &&
                _vm.errors.handsOnExperiences[index] &&
                _vm.errors.handsOnExperiences[index].rating
              ),expression:"\n                errors.handsOnExperiences &&\n                errors.handsOnExperiences[index] &&\n                errors.handsOnExperiences[index].rating\n              "}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.handsOnExperiences && _vm.errors.handsOnExperiences[index] && _vm.errors.handsOnExperiences[index].rating))])],1)],1)],1)}),1)],1):_vm._e(),_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"label":"Do you currently have an active Coalition of Construction Safety (CCS) card?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.hasCcsCard),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "hasCcsCard", $$v)},expression:"fields.jobSeeker.hasCcsCard"}})]}}])}),_c('b-form-group',{class:[
      'gfb-radio-container',
      { 'pill-error': _vm.errors.oshaCertification } ],attrs:{"label":"Do you hold an OSHA certification?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.oshaCertificationOptions,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.oshaCertification),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "oshaCertification", $$v)},expression:"fields.jobSeeker.oshaCertification"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.oshaCertification),expression:"errors.oshaCertification"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.oshaCertification))])]}}])}),_c('b-form-group',{attrs:{"label":"List any additional certifications, licenses, skills, or training you\n      possess that will make you valuable to employers"}},[_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"6"},model:{value:(_vm.fields.jobSeeker.idealJobDescription),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "idealJobDescription", $$v)},expression:"fields.jobSeeker.idealJobDescription"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }