var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex-2-cols"},[_c('base-input',{class:{
        'input-group-alternative': true,
        'input-error': _vm.errors.name,
      },attrs:{"placeholder":"Name*","type":"text","error":_vm.errors.name},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('base-input',{class:{
        'input-group-alternative': true,
        'input-error': _vm.errors.email,
      },attrs:{"placeholder":"Email Address*","type":"email","error":_vm.errors.email,"disabled":""},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}}),_c('base-input',{class:{
        'input-group-alternative': true,
        'input-error': _vm.errors.city,
        'pr-1': true,
      },attrs:{"placeholder":"City*","type":"text","error":_vm.errors.city},model:{value:(_vm.fields.city),callback:function ($$v) {_vm.$set(_vm.fields, "city", $$v)},expression:"fields.city"}}),_c('div',{staticClass:"address-wrapper"},[_c('div',{class:{
          'input-group-alternative': true,
          'input-error': _vm.errors.state,
          'pr-1': true,
        }},[_c('FormDropdown',{attrs:{"title":'State*',"isInValid":_vm.errors.state,"selected":_vm.fields.state},on:{"update-value":_vm.handleStateChange},scopedSlots:_vm._u([{key:"options",fn:function(){return _vm._l((_vm.stateOptions.map(function (state) { return state; })),function(state){return _c('option',{key:state.abbreviation,domProps:{"value":state.abbreviation}},[_vm._v(" "+_vm._s(state.name)+" ")])})},proxy:true}])}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.state),expression:"errors.state"}],attrs:{"nonInput":""}},[_vm._v(" "+_vm._s(_vm.errors.state)+" ")])],1),_c('base-input',{class:{
          'input-group-alternative': true,
          'input-error': _vm.errors.zip,
          'zip-code-input': true,
        },attrs:{"placeholder":"Zip Code*","type":"text","error":_vm.errors.zip},model:{value:(_vm.fields.zip),callback:function ($$v) {_vm.$set(_vm.fields, "zip", $$v)},expression:"fields.zip"}})],1),_c('phone-input',{attrs:{"inputClasses":("input-group-alternative phone-input " + (_vm.errors.phoneNumber ? 'input-error' : '')),"placeholder":"Phone number*","border-radius":14,"error":_vm.errors.phoneNumber,"value":_vm.fields.phoneNumber},on:{"on-change":function($event){_vm.fields.phoneNumber = $event}}})],1),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.isAdult } ],attrs:{"label":"Are you at least 18 years old?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.isAdult,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.isAdult),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "isAdult", $$v)},expression:"fields.jobSeeker.isAdult"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.isAdult),expression:"errors.isAdult"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.isAdult))])]}}])}),_c('b-form-group',{class:[
      'input-group-alternative',
      'gfb-radio-container',
      'required',
      { 'input-error': _vm.errors.latestEducation } ],attrs:{"label":"Latest completed education"}},[_c('FormDropdown',{attrs:{"title":'Select Education *',"isInValid":_vm.errors.latestEducation,"selected":_vm.fields.jobSeeker.latestEducation,"classNames":['education-select']},on:{"update-value":_vm.handleEducationChange},scopedSlots:_vm._u([{key:"options",fn:function(){return _vm._l((_vm.latestEducationOptions.map(
            function (education) { return education; }
          )),function(education){return _c('option',{key:education,domProps:{"value":education}},[_vm._v(" "+_vm._s(education)+" ")])})},proxy:true}])}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.latestEducation),expression:"errors.latestEducation"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.latestEducation))])],1),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.validDriversLicense } ],attrs:{"label":"Do you have a valid driver’s license?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.validDriversLicense),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "validDriversLicense", $$v)},expression:"fields.jobSeeker.validDriversLicense"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.validDriversLicense),expression:"errors.validDriversLicense"}],attrs:{"nonInput":""}},[_vm._v(" "+_vm._s(_vm.errors.validDriversLicense)+" ")])]}}])}),_c('b-form-group',{class:['gfb-radio-container', { 'pill-error': _vm.errors.cdlClass }],attrs:{"label":"Do you have a valid CDL license?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.validCdlLicense),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "validCdlLicense", $$v)},expression:"fields.jobSeeker.validCdlLicense"}})]}}])}),(_vm.fields.jobSeeker.validCdlLicense)?_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"label":"What class?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.cdlClassOptions,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.cdlClass),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "cdlClass", $$v)},expression:"fields.jobSeeker.cdlClass"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.cdlClass),expression:"errors.cdlClass"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.cdlClass))])]}}],null,false,1690959888)}):_vm._e(),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.reliableTransportation } ],attrs:{"label":"Do you have reliable transportation?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.reliableTransportation),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "reliableTransportation", $$v)},expression:"fields.jobSeeker.reliableTransportation"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.reliableTransportation),expression:"errors.reliableTransportation"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.reliableTransportation))])]}}])}),_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"label":"Are you a veteran?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.isVeteran),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "isVeteran", $$v)},expression:"fields.jobSeeker.isVeteran"}})]}}])}),(_vm.fields.jobSeeker.isVeteran)?_c('b-form-group',{staticClass:"gfb-radio-container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.veteranStatusOptions,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.veteranStatusOptions),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "veteranStatusOptions", $$v)},expression:"fields.jobSeeker.veteranStatusOptions"}})]}}],null,false,3219870417)}):_vm._e(),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.authorizedInUS } ],attrs:{"label":"Are you legally authorized to work in the United States?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.authorizedInUS),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "authorizedInUS", $$v)},expression:"fields.jobSeeker.authorizedInUS"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.authorizedInUS),expression:"errors.authorizedInUS"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.authorizedInUS))])]}}])}),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.requireSponsorship } ],attrs:{"label":"Do you now, or will you in the future, require immigration sponsorship for work authorization (e.g. H-1B)?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-describedby":ariaDescribedby},model:{value:(_vm.fields.jobSeeker.requireSponsorship),callback:function ($$v) {_vm.$set(_vm.fields.jobSeeker, "requireSponsorship", $$v)},expression:"fields.jobSeeker.requireSponsorship"}}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.requireSponsorship),expression:"errors.requireSponsorship"}],attrs:{"nonInput":""}},[_vm._v(_vm._s(_vm.errors.requireSponsorship))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }