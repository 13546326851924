<template>
  <div>
    <h2 class="font-22-700 dark-text mb-5 text-center">Contact Information</h2>
    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.name,
      }"
      placeholder="Company LTD *"
      type="text"
      v-model="fields.name"
      :error="errors.name"
    >
    </base-input>
    <base-input
      class="input-group-alternative"
      placeholder="Principle Contact Name or Сompany Owner"
      type="text"
      v-model="fields.principleContact"
    >
    </base-input>

    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.email,
      }"
      placeholder="Email Address *"
      type="text"
      v-model="fields.email"
      :error="errors.email"
      disabled
    >
    </base-input>

    <base-input
      class="input-group-alternative"
      placeholder="Company Address Line 1"
      type="text"
      v-model="fields.address"
    >
    </base-input>
    <base-input
      class="input-group-alternative"
      placeholder="Company Address Line 2"
      type="text"
      v-model="fields.address2"
    >
    </base-input>
    <div class="d-flex">
      <base-input
        :class="{
          'input-group-alternative': true,
          'pr-1': true,
          'input-error': errors.city,
        }"
        placeholder="City *"
        type="text"
        v-model="fields.city"
        :error="errors.city"
      >
      </base-input>
      <div
        :class="{
          'form-group': true,
          'input-group-alternative': true,
          'pr-1': true,
          'input-error': errors.state,
        }"
      >
        <FormDropdown
          :title="'State*'"
          :isInValid="errors.state"
          :selected="fields.state"
          @update-value="handleStateChange"
        >
          <template v-slot:options>
            <option
              v-for="state in stateOptions.map((state) => state)"
              :key="state.abbreviation"
              v-bind:value="state.abbreviation"
            >
              {{ state.name }}
            </option>
          </template>
        </FormDropdown>
        <form-error nonInput v-show="errors.state">
          {{ errors.state }}
        </form-error>
      </div>
      <base-input
        :class="{
          'input-group-alternative': true,
          'input-error': errors.zip,
        }"
        placeholder="Zip Code *"
        type="text"
        v-model="fields.zip"
        :error="errors.zip"
      >
      </base-input>
    </div>

    <phone-input
      inputClasses="phone-input"
      placeholder="Phone number *"
      :border-radius="14"
      :error="errors.phoneNumber"
      :value="fields.phoneNumber"
      @on-change="fields.phoneNumber = $event"
    />

    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.website,
      }"
      placeholder="Website"
      type="text"
      v-model="fields.website"
      :error="errors.website"
    >
    </base-input>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import {
  validateEmail,
  validateUrl,
  validateUsPhoneNumber,
  validateZipCode,
  deNullifyErrors,
} from "@/utils/validations"
import options from "@/utils/options"
import FormDropdown from "../../FormDropdown.vue"

export default {
  name: "company-profile-contact-information",
  components: { FormDropdown },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      stateOptions: options.stateOptions,
    }
  },
  mounted() {
    EventBus.$on("validate-company-contact-info", () => {
      this.validateContactInfo()
    })
  },
  computed: {
    isInvalidSelect() {
      return this.fields.state === ""
    },
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-contact-info")
  },
  methods: {
    validateContactInfo() {
      let errors = {
        email: !this.fields.email
          ? "Please enter your email address"
          : !validateEmail(this.fields.email)
          ? "Please enter a valid email address"
          : null,
        phoneNumber: !validateUsPhoneNumber(this.fields.phoneNumber)
          ? "Please enter a valid phone number"
          : null,
        website:
          this.fields.website && !validateUrl(this.fields.website)
            ? "Please enter a valid web address"
            : null,
        name: !this.fields.name ? "Please enter your company name" : null,
        state: !this.fields.state ? "This is required" : null,
        city: !this.fields.city ? "This is required" : null,
        zip: !this.fields.zip
          ? "Please enter your company ZIP code"
          : !validateZipCode(this.fields.zip)
          ? "Please enter a valid ZIP code"
          : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
    handleStateChange(newValue) {
      this.fields.state = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.input-error {
  .state-select.invalid-select {
    color: #e24646 !important;
  }
}

.state-select {
  box-shadow: none;
  background: #ffffff;
  border: 2px solid var(--Gray-300, #b0b9c9);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  line-height: 50px;
  padding: 0 1rem !important;
  color: #003049;
  font-weight: 500;
  max-width: 150px;

  &:hover {
    border: 2px solid var(--Gray-500, #677690) !important;
  }

  &:focus {
    border-color: #0095e2 !important;
    outline: unset !important;
  }

  option {
    color: #003049;
  }

  &.invalid-select,
  option [disabled] {
    color: #adb5bd;
  }
}
</style>
