export default {
  methods: {
    async validatePhotos(
      photos,
      sizeLimit = 10 * 1024 * 1024,
      squareRequired = false
    ) {
      if (!photos) return null
      const dimensions = await Promise.all(
        photos.filter(Boolean).map((photo) => {
          const dataUrl = URL.createObjectURL(photo)
          return new Promise((resolve) => {
            const img = new Image()
            img.onload = function () {
              const height = img.height
              const width = img.width
              URL.revokeObjectURL(dataUrl)
              resolve({
                height,
                width,
              })
            }
            img.src = dataUrl
          })
        })
      )
      const error = dimensions.reduce((error, file) => {
        const { height, width } = file
        const photoFormatError =
          squareRequired && height !== width
            ? "Please upload a square image file"
            : width < 50
            ? "An image file at least 50 pixels wide is recommended"
            : null
        return error
          ? error
          : photoFormatError
          ? photoFormatError
          : file.size > sizeLimit
          ? `Files must be under ${sizeLimit / 1024 / 1024} MB in size`
          : null
      }, null)
      return error
    },
  },
}
