<template>
  <select
    :class="computedClasses"
    @change="updateValue($event.target.value)"
    v-model="selected"
  >
    <option style="color: #7b809d" value="" disabled selected>
      {{ title }}
    </option>
    <slot name="options"></slot>
  </select>
</template>
<script>
export default {
  name: "form-drop-down",
  props: {
    title: {
      type: String,
      default: "Select option",
    },
    isInValid: {
      type: Boolean,
      default: false,
    },
    classNames: {
      type: [String, Array],
      default: "",
    },
    selected: {
      type: String,
      default: "",
    },
  },
  computed: {
    computedClasses() {
      return [
        "state-select",
        "pr-1",
        this.isInValid ? "invalid-select" : "",
        ...(Array.isArray(this.classNames)
          ? this.classNames
          : [this.classNames]),
      ]
        .filter(Boolean)
        .join(" ")
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("update-value", value)
    },
  },
}
</script>
<style lang="scss" scoped>
.input-error {
  .state-select.invalid-select {
    color: #e24646 !important;
  }
}

.state-select {
  box-shadow: none;
  background: #ffffff;
  border: 2px solid var(--Gray-300, #b0b9c9);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  line-height: 50px;
  padding: 0 1rem !important;
  color: #003049;
  font-weight: 500;
  max-width: 150px;

  &:hover {
    border: 2px solid var(--Gray-500, #677690) !important;
  }

  &:focus {
    border-color: #0095e2 !important;
    outline: unset !important;
  }

  option {
    color: #003049;
  }

  &.invalid-select,
  option [disabled] {
    color: #adb5bd;
  }
}
</style>
