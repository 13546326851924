var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-22-700 dark-text mb-5 text-center"},[_vm._v("Contact Information")]),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.name,
    },attrs:{"placeholder":"Company LTD *","type":"text","error":_vm.errors.name},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Principle Contact Name or Сompany Owner","type":"text"},model:{value:(_vm.fields.principleContact),callback:function ($$v) {_vm.$set(_vm.fields, "principleContact", $$v)},expression:"fields.principleContact"}}),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.email,
    },attrs:{"placeholder":"Email Address *","type":"text","error":_vm.errors.email,"disabled":""},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}}),_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Company Address Line 1","type":"text"},model:{value:(_vm.fields.address),callback:function ($$v) {_vm.$set(_vm.fields, "address", $$v)},expression:"fields.address"}}),_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Company Address Line 2","type":"text"},model:{value:(_vm.fields.address2),callback:function ($$v) {_vm.$set(_vm.fields, "address2", $$v)},expression:"fields.address2"}}),_c('div',{staticClass:"d-flex"},[_c('base-input',{class:{
        'input-group-alternative': true,
        'pr-1': true,
        'input-error': _vm.errors.city,
      },attrs:{"placeholder":"City *","type":"text","error":_vm.errors.city},model:{value:(_vm.fields.city),callback:function ($$v) {_vm.$set(_vm.fields, "city", $$v)},expression:"fields.city"}}),_c('div',{class:{
        'form-group': true,
        'input-group-alternative': true,
        'pr-1': true,
        'input-error': _vm.errors.state,
      }},[_c('FormDropdown',{attrs:{"title":'State*',"isInValid":_vm.errors.state,"selected":_vm.fields.state},on:{"update-value":_vm.handleStateChange},scopedSlots:_vm._u([{key:"options",fn:function(){return _vm._l((_vm.stateOptions.map(function (state) { return state; })),function(state){return _c('option',{key:state.abbreviation,domProps:{"value":state.abbreviation}},[_vm._v(" "+_vm._s(state.name)+" ")])})},proxy:true}])}),_c('form-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.state),expression:"errors.state"}],attrs:{"nonInput":""}},[_vm._v(" "+_vm._s(_vm.errors.state)+" ")])],1),_c('base-input',{class:{
        'input-group-alternative': true,
        'input-error': _vm.errors.zip,
      },attrs:{"placeholder":"Zip Code *","type":"text","error":_vm.errors.zip},model:{value:(_vm.fields.zip),callback:function ($$v) {_vm.$set(_vm.fields, "zip", $$v)},expression:"fields.zip"}})],1),_c('phone-input',{attrs:{"inputClasses":"phone-input","placeholder":"Phone number *","border-radius":14,"error":_vm.errors.phoneNumber,"value":_vm.fields.phoneNumber},on:{"on-change":function($event){_vm.fields.phoneNumber = $event}}}),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.website,
    },attrs:{"placeholder":"Website","type":"text","error":_vm.errors.website},model:{value:(_vm.fields.website),callback:function ($$v) {_vm.$set(_vm.fields, "website", $$v)},expression:"fields.website"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }