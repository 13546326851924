var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.provider && _vm.errors.provider.isVendor } ],attrs:{"label":"Are you a material vendor?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-invalid":!(_vm.errors.provider && _vm.errors.provider.isVendor)},model:{value:(_vm.fields.provider.isVendor),callback:function ($$v) {_vm.$set(_vm.fields.provider, "isVendor", $$v)},expression:"fields.provider.isVendor"}},[_c('b-form-invalid-feedback',{attrs:{"state":!(_vm.errors.provider && _vm.errors.provider.isVendor)}},[_vm._v(_vm._s(_vm.errors.provider && _vm.errors.provider.isVendor))])],1)],1),(_vm.fields.provider.isVendor)?_c('b-form-group',{class:[
      'required',
      { 'pill-error': _vm.errors && _vm.errors.tradesRelatedToProduct } ],attrs:{"label":"Please select all trade categories  most relevant to the products, equipment, \n          or materials you sell *"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.categoryOptions,"selectedList":_vm.fields.tradesRelatedToProduct,"selectLabel":"Search or select category"},on:{"remove-option":_vm.removeTrade,"select-option":_vm.addTrade}}),_c('b-form-invalid-feedback',{attrs:{"state":!(_vm.errors && _vm.errors.tradesRelatedToProduct)}},[_vm._v(_vm._s(_vm.errors && _vm.errors.tradesRelatedToProduct))])],1):_vm._e(),(_vm.fields.provider.isVendor)?_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      'mt-4',
      { 'pill-error': _vm.errors && _vm.errors.providesInstallLaborServices } ],attrs:{"label":"Does your business provide installation labor services for the products or materials you sell?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse,"aria-invalid":!(_vm.errors && _vm.errors.providesInstallLaborServices)},model:{value:(_vm.fields.providesInstallLaborServices),callback:function ($$v) {_vm.$set(_vm.fields, "providesInstallLaborServices", $$v)},expression:"fields.providesInstallLaborServices"}},[_c('b-form-invalid-feedback',{attrs:{"state":!(_vm.errors && _vm.errors.providesInstallLaborServices)}},[_vm._v(_vm._s(_vm.errors && _vm.errors.providesInstallLaborServices))])],1)],1):_vm._e(),(!_vm.fields.provider.isVendor)?_c('b-form-group',{attrs:{"label":"Please select only the trade categories specific to the labor services you \n          perform using your own employee labor and NOT subcontracted labor"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.categoryOptions,"selectedList":_vm.fields.selfPerformWorkCategories,"selectLabel":"Search or select category"},on:{"remove-option":_vm.removeSelfPerformCategory,"select-option":_vm.addSelfPerformCategory}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }