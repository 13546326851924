<template>
  <div>
    <div class="flex-2-cols">
      <base-input
        :class="{
          'input-group-alternative': true,
          'input-error': errors.name,
        }"
        placeholder="Name*"
        type="text"
        v-model="fields.name"
        :error="errors.name"
      >
      </base-input>

      <base-input
        :class="{
          'input-group-alternative': true,
          'input-error': errors.email,
        }"
        placeholder="Email Address*"
        type="email"
        v-model="fields.email"
        :error="errors.email"
        disabled
      >
      </base-input>

      <base-input
        :class="{
          'input-group-alternative': true,
          'input-error': errors.city,
          'pr-1': true,
        }"
        placeholder="City*"
        type="text"
        v-model="fields.city"
        :error="errors.city"
      >
      </base-input>

      <div class="address-wrapper">
        <div
          :class="{
            'input-group-alternative': true,
            'input-error': errors.state,
            'pr-1': true,
          }"
        >
          <FormDropdown
            :title="'State*'"
            :isInValid="errors.state"
            :selected="fields.state"
            @update-value="handleStateChange"
          >
            <template v-slot:options>
              <option
                v-for="state in stateOptions.map((state) => state)"
                :key="state.abbreviation"
                v-bind:value="state.abbreviation"
              >
                {{ state.name }}
              </option>
            </template>
          </FormDropdown>
          <form-error nonInput v-show="errors.state">
            {{ errors.state }}
          </form-error>
        </div>
        <base-input
          :class="{
            'input-group-alternative': true,
            'input-error': errors.zip,
            'zip-code-input': true,
          }"
          placeholder="Zip Code*"
          type="text"
          v-model="fields.zip"
          :error="errors.zip"
        >
        </base-input>
      </div>
      <phone-input
        :inputClasses="`input-group-alternative phone-input ${
          errors.phoneNumber ? 'input-error' : ''
        }`"
        placeholder="Phone number*"
        :border-radius="14"
        :error="errors.phoneNumber"
        :value="fields.phoneNumber"
        @on-change="fields.phoneNumber = $event"
      />
    </div>

    <b-form-group
      label="Are you at least 18 years old?"
      v-slot="{ ariaDescribedby }"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.isAdult },
      ]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.isAdult"
        :options="isAdult"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.isAdult">{{
        errors.isAdult
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="Latest completed education"
      :class="[
        'input-group-alternative',
        'gfb-radio-container',
        'required',
        { 'input-error': errors.latestEducation },
      ]"
    >
      <FormDropdown
        :title="'Select Education *'"
        :isInValid="errors.latestEducation"
        :selected="fields.jobSeeker.latestEducation"
        :classNames="['education-select']"
        @update-value="handleEducationChange"
      >
        <template v-slot:options>
          <option
            v-for="education in latestEducationOptions.map(
              (education) => education
            )"
            :key="education"
            v-bind:value="education"
          >
            {{ education }}
          </option>
        </template>
      </FormDropdown>
      <form-error nonInput v-show="errors.latestEducation">{{
        errors.latestEducation
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="Do you have a valid driver’s license?"
      v-slot="{ ariaDescribedby }"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.validDriversLicense },
      ]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.validDriversLicense"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.validDriversLicense">
        {{ errors.validDriversLicense }}
      </form-error>
    </b-form-group>

    <b-form-group
      label="Do you have a valid CDL license?"
      v-slot="{ ariaDescribedby }"
      :class="['gfb-radio-container', { 'pill-error': errors.cdlClass }]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.validCdlLicense"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      v-if="fields.jobSeeker.validCdlLicense"
      label="What class?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.cdlClass"
        :options="cdlClassOptions"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.cdlClass">{{
        errors.cdlClass
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="Do you have reliable transportation?"
      v-slot="{ ariaDescribedby }"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.reliableTransportation },
      ]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.reliableTransportation"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.reliableTransportation">{{
        errors.reliableTransportation
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="Are you a veteran?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.isVeteran"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
      v-if="fields.jobSeeker.isVeteran"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.veteranStatusOptions"
        :options="veteranStatusOptions"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="Are you legally authorized to work in the United States?"
      v-slot="{ ariaDescribedby }"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.authorizedInUS },
      ]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.authorizedInUS"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.authorizedInUS">{{
        errors.authorizedInUS
      }}</form-error>
    </b-form-group>
    <b-form-group
      label="Do you now, or will you in the future, require immigration sponsorship for work authorization (e.g. H-1B)?"
      v-slot="{ ariaDescribedby }"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.requireSponsorship },
      ]"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.requireSponsorship"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.requireSponsorship">{{
        errors.requireSponsorship
      }}</form-error>
    </b-form-group>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import {
  validateEmail,
  validateUsPhoneNumber,
  validateZipCode,
  deNullifyErrors,
  isNullish,
  requiredError,
} from "@/utils/validations"
import options from "@/utils/options"
import FormDropdown from "../../FormDropdown.vue"

export default {
  name: "candidate-profile-basic-information",
  components: { FormDropdown },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      stateOptions: options.stateOptions,
      trueFalse: options.trueFalse,
      cdlClassOptions: options.cdlClassOptions,
      latestEducationOptions: options.latestEducationOptions,
      isAdult: options.trueFalse,
      veteranStatusOptions: options.veteranStatusOptions,
    }
  },
  mounted() {
    EventBus.$on("validate-candidate-basic-info", () => {
      this.validate()
    })
  },
  computed: {
    isInvalidState() {
      return this.fields.state === ""
    },
    isInvalidEducation() {
      return this.fields.jobSeeker.latestEducation === ""
    },
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-candidate-basic-info")
  },
  methods: {
    validate() {
      let fields = this.fields
      let errors = {
        name: !fields.name ? "This is required" : null,
        email: requiredError(
          fields.email,
          !validateEmail(fields.email)
            ? "Please enter a valid email address"
            : null
        ),
        state: !fields.state ? "This is required" : null,
        city: !fields.city ? "This is required" : null,
        zip: requiredError(
          fields.zip,
          !validateZipCode(fields.zip) ? "Please enter a valid ZIP code" : null
        ),
        phoneNumber: !validateUsPhoneNumber(fields.phoneNumber)
          ? "Please enter a valid phone number"
          : null,
        latestEducation: requiredError(fields.jobSeeker.latestEducation),
        isAdult: requiredError(fields.jobSeeker.isAdult),
        authorizedInUS: requiredError(fields.jobSeeker.authorizedInUS),
        requireSponsorship: requiredError(fields.jobSeeker.requireSponsorship),
        validDriversLicense: requiredError(
          fields.jobSeeker.validDriversLicense
        ),
        reliableTransportation: requiredError(
          fields.jobSeeker.reliableTransportation
        ),
        cdlClass:
          fields.jobSeeker.validCdlLicense &&
          isNullish(fields.jobSeeker.cdlClass)
            ? "This is required"
            : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
    handleEducationChange(newValue) {
      this.fields.jobSeeker.latestEducation = newValue
    },
    handleStateChange(newValue) {
      this.fields.state = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.address-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .zip-code-input {
    max-width: 68%;
    flex-grow: 2;
  }
}

.education-select {
  width: 364px;
  max-width: unset;
}
</style>
